import { useDispatch, useSelector } from "react-redux";

function useModal() {
  const dispatch = useDispatch();
  const { show } = useSelector(state => state.app.modal)

  function setModal({
    children,
    modalWidth = 452,
    renderFooter,
    show = false,
    showCloseButton = false,
    title = "",
    titleWidth = 256,
  }) {
    dispatch({ type: "CHANGE_MODAL", payload: { show, showCloseButton, title, titleWidth, modalWidth, children, renderFooter } })
  }
  function closeModal() {
    dispatch({ type: "CHANGE_MODAL", payload: { show: false } })
  }

  return { show, setModal, closeModal }
}

export { useModal }