import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Edit3, Mail } from 'react-feather'
import { useParams, useHistory } from 'react-router-dom'
import _ from 'lodash'
import axios from 'axios'

import { useModal } from '../../hooks/useModal'

import BackButton from './components/BackButton'
import Contact from './components/Contact'

import Button from '../../components/Button'
import CheckboxList from '../../components/CheckboxList'
import Header from '../../components/Header'
import Input from '../../components/Input'
import ProgressBar from '../../components/ProgressBar'
import Select from '../../components/Select'
import Spinner from '../../components/Spinner'


import './index.scss'

function Form() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { groupName } = useParams()
  const { color, sac, support, groupId, secondaryColor, fontColor } = useSelector(state => state.app)
  const [loading, setLoading] = useState(true)
  const [showSenderInfos, setShowSenderInfos] = useState(false)
  const [showThanks, setShowThanks] = useState(false)
  const [showIntroMobile, setShowIntroMobile] = useState(true)
  const [isLastQuestion, setIsLastQuestion] = useState(false)
  const [nextQuestionLoading, setNextQuestionLoading] = useState(false)
  const [sendingLoading, setSendingLoading] = useState(false)

  const [nextQuestionId, setNextQuestionId] = useState("")
  const [totalQuestions, setTotalQuestions] = useState(4)
  const [currentStep, setCurrentStep] = useState(0)

  const [form, setForm] = useState([])
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [document, setDocument,] = useState('')
  const [phone, setPhone,] = useState('')
  const [contactType, setContactType] = useState('')

  const { setModal } = useModal()

  useEffect(() => {
    getDesign(groupName)
  }, [groupName])

  useEffect(() => {
    setLastQuestion()
  }, [form, currentStep])

  function handleClickGoBack() {
    if (currentStep > 0 && !showThanks) {
      setCurrentStep(currentStep - 1)
      setShowSenderInfos(false)
      setNextQuestionId('')
    } else if (showThanks) {
      handleClickGoHome()
    } else {
      handleClickGoHome()
    }
  }

  function handleClickNextQuestion() {
    if (!_.isEmpty(form[currentStep + 1]) && _.isEmpty(nextQuestionId)) {
      setCurrentStep(currentStep + 1)
    } else if (!_.isEmpty(form[currentStep].answer) && !_.isEmpty(nextQuestionId)) {
      setCurrentStep(currentStep + 1)
      getQuestion(nextQuestionId, currentStep + 1)
    }
  }

  function handleClickSenderInfos() {
    if (!_.isEmpty(form[currentStep].answer)) {
      setCurrentStep(currentStep + 1)
      setShowSenderInfos(true)

      //remove questions after the current step
      const newForm = form.slice(0, currentStep + 1)
      setForm(newForm)
    }
  }

  function handleClickSubmit() {
    const formCopy = form.map(e => ({ question: e.question, answer: e.answer }))
    setFormSubmit(formCopy, name, email, document, phone, contactType, groupId)
  }

  function handleClickGoHome() {
    window.location.assign('https://www.semprocesso.com.br/falejur')
  }

  function handleTextAnswer(evt) {
    const formCopy = [...form]
    formCopy[currentStep] = { ...formCopy[currentStep], answer: evt.target.value }
    setForm(formCopy)
    setNextQuestionId(formCopy[currentStep].options[0].nextQuestionId)
  }

  function handleSelectAnswer(evt) {
    const formCopy = [...form]
    formCopy[currentStep] = { ...formCopy[currentStep], answer: evt.text }
    setForm(formCopy)
    setNextQuestionId(evt.nextQuestionId)
  }

  function handleCheckboxAnswer(evt) {
    const formCopy = [...form]
    formCopy[currentStep] = { ...formCopy[currentStep], answer: evt }
    setForm(formCopy)
    setNextQuestionId(formCopy[currentStep].options[0].nextQuestionId)
  }

  function handleInput(evt, mask) {
    const formCopy = [...form]
    formCopy[currentStep] = { ...formCopy[currentStep], answer: evt }
    setForm(formCopy)
    setNextQuestionId(formCopy[currentStep].options[0].nextQuestionId)

    switch (mask) {
      case 'cpf':
        setDocument(evt)
        break;
      default:
        break;
    }
  }

  function getDesign(value) {
    axios.get('/groups/get-design', { params: { name: value } })
      .then(response => {
        setLoading(false)

        setForm([response.data.question])

        dispatch({ type: "CHANGE_APP_RESET_FORM" })
        dispatch({ type: "CHANGE_APP_LOGO_URL", payload: response.data.logo })
        dispatch({ type: "CHANGE_APP_COLOR", payload: response.data.group.rgb })
        dispatch({ type: "CHANGE_APP_SAC", payload: response.data.group.sac })
        dispatch({ type: "CHANGE_APP_SUPPORT", payload: response.data.group.ouvidoria })
        dispatch({ type: "CHANGE_APP_GROUP_ID", payload: response.data.group._id })
        dispatch({ type: "CHANGE_APP_SECONDARY_COLOR", payload: response.data.group.secondaryRgb })
        dispatch({ type: "CHANGE_APP_FONT_COLOR", payload: response.data.group.fontColor })
      })
      .catch(err => {
        console.log(err)
        handleClickGoHome()
      })
  }

  function getQuestion(questionId, currentStep) {
    setNextQuestionLoading(true)
    setNextQuestionId("")
    
    if (form[currentStep - 1].type === 'checkbox') {
      let answers = form[currentStep - 1].answer.split(',')
      if (answers.length > 1) {
        questionId = form[currentStep - 1].nextQuestionId
      }
      else{
        let index = form[currentStep - 1].options.findIndex(element => element.text === answers[0])
        questionId = form[currentStep - 1].options[index].nextQuestionId
      }
    }
    
    axios.get('/trees/get-question', { params: { groupId: groupId, questionId: questionId, numberOfResponded: form.length } })
      .then(response => {
        setNextQuestionLoading(false)
        setFormQuestion(currentStep, response.data.question)
        setTotalQuestions(response.data.totalQuestions + 2)
      })
      .catch(err => {
        console.log(err)
        setNextQuestionLoading(false)
      })
  }

  function setFormQuestion(currentStep, question) {
    let formCopy = [...form]
    if (formCopy[currentStep]?.question !== question.question) {
      formCopy = formCopy.slice(0, currentStep)
    }
    formCopy[currentStep] = question
    setForm(formCopy)
  }

  function setLastQuestion() {
    if (!_.isEmpty(form[currentStep])) {
      if ((form[currentStep].options.length === 1 && form[currentStep].options[0].nextQuestionId === null) || (form[currentStep]?.options.find(o => o.text === form[currentStep]?.answer)?.nextQuestionId === null && form[currentStep].type !== 'checkbox')) {
        setIsLastQuestion(true)
      } else {
        setIsLastQuestion(false)
      }
    }
  }

  function setFormSubmit(form, contactName, contactEmail, contactDocument, contactPhone, contactType, groupId) {
    if (!_.isEmpty(contactName) && !_.isEmpty(contactEmail) && !_.isEmpty(contactDocument)) {
      setSendingLoading(true)
      axios.post('/tickets/send-form', { form, contactName, contactEmail, contactDocument, contactPhone, contactType, groupId, offline: false, userProfile: 'client' })
        .then(response => {
          setSendingLoading(false)
          setShowSenderInfos(false)
          setShowThanks(true)
          setCurrentStep(currentStep + 1)
          setTotalQuestions(currentStep + 1)
        })
        .catch(err => {
          console.log(err.response.status)
          if (err.response.status === 412) {
            setModal({ show: true, showCloseButton: true, children: 'Já existe um ticket com esse CPF e/ou telefone' })
          }
          setSendingLoading(false)
        })
    }
  }

  function renderField(form, currentStep) {
    switch (form[currentStep]?.type) {
      case "select":
        return <Select marginBottom={'16px'} options={form[currentStep]?.options} value={form[currentStep]?.answer} onChange={handleSelectAnswer} />
      case "text":
        return <textarea placeholder="Escreva aqui" value={form[currentStep]?.answer} onChange={handleTextAnswer} />
      case "checkbox":
        return <CheckboxList options={form[currentStep]?.options} value={form[currentStep]?.answer} onChange={handleCheckboxAnswer} color={color} />
      case "input":
        return <Input placeholder="Escreva aqui" value={form[currentStep]?.answer} onChange={evt => handleInput(evt, form[currentStep]?.mask)} mask={form[currentStep]?.mask} />
      default: return null
    }
  }

  function renderButton(isLastQuestion, nextQuestionLoading, color) {
    if (!isLastQuestion)
      return <Button to={false} loading={nextQuestionLoading} backgroundColor={color} height='48px' onClick={handleClickNextQuestion}>Ir para próxima</Button>
    if (isLastQuestion)
      return <Button to={false} loading={nextQuestionLoading} backgroundColor={color} height='48px' onClick={handleClickSenderInfos}>Dados de contato</Button>
  }

  return (
    <>
      {!loading && <Header />}
      <div className="form">
        <div className='form-content'>
          <div className='container'>
            {/* {loading && <Spinner />} */}
            {!loading &&
              <>
                <div className={showIntroMobile ? 'form__info form__info--visible' : 'form__info'} style={{ color: fontColor }}>
                  <BackButton isFirst={currentStep === 0} isLast={showThanks} onClick={() => !nextQuestionLoading && handleClickGoBack()} />
                  <h1>Estamos aqui para solucionar de vez o seu problema.</h1>
                  <h4>Não conseguiu encontrar uma solução através do SAC ou Ouvidoria?</h4>
                  <h4>Fale direto com a área jurídica responsável e resolva seu problem sem ter que entrar com um processo no Judiciário.</h4>
                  <h3>1. Preencha o formulário</h3>
                  <h3>2. Receba por email a resposta do setor jurídico</h3>
                  <h3>3. Solucione seu problema através de um acordo</h3>
                  <Button to={false} backgroundColor={fontColor} color={color} height='48px' onClick={() => setShowIntroMobile(false)}>Começar</Button>
                </div>
                <div className={!showIntroMobile ? 'form__question form__question--visible' : 'form__question'} >
                  <BackButton isFirst={currentStep === 0} isLast={showThanks} onClick={() => !nextQuestionLoading && handleClickGoBack()} />
                  <div className='form__question__content'>
                    <ProgressBar step={currentStep + 1} total={totalQuestions} color={color} marginTop={'38px'} />
                    {(!showSenderInfos && !showThanks) &&
                      <>
                        <h2>{form[currentStep]?.question}</h2>
                        {renderField(form, currentStep)}
                        {renderButton(isLastQuestion, nextQuestionLoading, color)}
                      </>
                    }
                    {showSenderInfos &&
                      <Contact
                        color={color}
                        name={name}
                        email={email}
                        document={document}
                        phone={phone}
                        contactType={contactType}
                        setName={setName}
                        setEmail={setEmail}
                        setDocument={setDocument}
                        setPhone={setPhone}
                        setContactType={setContactType}
                        submit={handleClickSubmit}
                        submitLoading={sendingLoading}
                      />
                    }
                    {showThanks &&
                      <>
                        <h2>Obrigado</h2>
                        <div className="form__thanks-item">
                          <div className="form__thanks-item__icon" style={{ color: color, borderColor: color }}><Mail /></div>
                          <span>Você receberá
                            {
                              contactType === 'Email' ?
                                <> um <b style={{ color: color, borderColor: color }}> email de confirmação</b> </> :
                                <> uma<b style={{ color: color, borderColor: color }}> mensagem de confirmação</b> </>
                            }
                            contendo o número do protocolo.</span>
                        </div>
                        <div className="form__thanks-item">
                          <div className="form__thanks-item__icon" style={{ color: color }}><Edit3 /></div>
                          <span>Em até 24h* você irá <b style={{ color: color }}>receber por {contactType === 'Email' ? 'email' : 'whatsapp'} uma resposta</b> do nosso setor jurídico.</span>
                        </div>
                      </>
                    }
                  </div>
                  <section className={currentStep === 0 ? "form__alerts form__alerts--visible" : "form__alerts"} >
                    <h1>Seu problema não aparece nessa lista?</h1>
                    <p>Talvez o seu caso seja mais simples de resolver do que você pensa.</p>
                    <p>Entre em contato com o SAC pelo {sac}{support && <>ou com a <a href={support} target="_blank" rel="noreferrer" style={{ color: color }}>Ouvidoria através desse link</a></>}.</p>
                  </section>
                  <section className={showThanks ? "form__alerts form__alerts--visible" : "form__alerts"} >
                    <h1>Obs:</h1>
                    <p>* Caso este chamado seja aberto fora do horário comercial ou no final de semana a resposta será enviada no próximo dia útil.</p>
                  </section>
                </div>
              </>
            }
          </div>
        </div>
        {
          !loading &&
          <div className='form-background'>
            <div className='form-background-left' style={{ backgroundImage: `linear-gradient(28.84deg, ${color} 8.14%, ${secondaryColor} 102.7%)` }} />
            <div className='form-background-right' />
            <div className={showIntroMobile ? 'form-background-mobile form-background-mobile--visible' : 'form-background-mobile'} style={{ backgroundImage: `linear-gradient(28.84deg, ${color} 8.14%, ${secondaryColor} 102.7%)` }} />
          </div>
        }
      </div >
    </>
  )
}

export default Form
