import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { cpf as cpfMask, validateEmail, validateCpf, phone as phoneMask, onlyNumber } from '../../../../constants/validate'
import { useModal } from '../../../../hooks/useModal'

import Button from '../../../../components/Button'
import Select from '../../../../components/Select'
import './index.scss'

function Contact({ color, name, setName, email, phone, contactType, setEmail, document, setDocument, setPhone, setContactType, submit, submitLoading }) {
  const dispatch = useDispatch()
  const { setModal } = useModal()
  const [nameError, setNameError] = useState(false)
  const [documentError, setDocumentError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [phoneError, setPhoneError] = useState(false)
  const [contactTypeError, setContactTypeError] = useState(false)

  useEffect(() => {
    if (documentError || emailError || phoneError || contactTypeError) {
      dispatch({ type: 'CHANGE_SNACKBAR', payload: "Campo(s) Inválido(s)" })
    }
  }, [documentError, emailError, phoneError, contactTypeError])

  function handleClickSubmit() {
    if (name === '') {
      setNameError(true)
    }
    else {
      setNameError(false)
    }

    if (!validateCpf(document)) {
      setDocumentError(true)
    }
    else {
      setDocumentError(false)
    }

    if (!validateEmail(email)) {
      setEmailError(true)
    }
    else {
      setEmailError(false)
    }

    if (phone.length < 11) {
      setPhoneError(true)
    }
    else {
      setPhoneError(false)
    }

    if (contactType === '') {
      setContactTypeError(true)
    }
    else {
      setContactTypeError(false)
    }

    if (documentError || emailError || phoneError || contactTypeError) {
    } else {
      submit()
    }
  }

  let options = [
    { text: 'Email' },
    { text: 'Whatsapp' }
  ]

  return (
    <div className='contact'>
      <h2>Insira seus dados</h2>
      <div className={nameError ? 'contact__input contact__input--error' : 'contact__input'}>Nome Completo: <input onChange={(e) => setName(e.target.value)} value={name} /> </div>
      <div className={documentError ? 'contact__input contact__input--error' : 'contact__input'}>CPF: <input inputMode='number' onChange={(e) => setDocument(cpfMask(e.target.value))} value={document} /> </div>
      <div className={emailError ? 'contact__input contact__input--error' : 'contact__input'}>Email: <input inputMode='email' type='email' onChange={(e) => setEmail(e.target.value)} value={email} /> </div>
      <div className={phoneError ? 'contact__input contact__input--error' : 'contact__input'}>Whatsapp: <input inputMode='number' type='phone' onChange={(e) => setPhone(onlyNumber(e.target.value))} value={phoneMask(phone)} /> </div>
      <div className={contactTypeError ? 'contact__input contact__input--error' : 'contact__input'}>Por onde deseja ser contatado? <Select options={options} onChange={setContactType} value={contactType} contact={true} /> </div>
      <Button to={false} loading={submitLoading} backgroundColor={color} width='184px' height='48px' onClick={(handleClickSubmit)}>Finalizar</Button>
    </div>

  )
}

export default Contact