import { useDispatch, useSelector } from 'react-redux'
import { X } from 'react-feather'

import './index.scss'

function Modal() {
  const dispatch = useDispatch()
  const { show, showCloseButton, title, titleWidth, modalWidth, children, renderFooter } = useSelector(state => state.app.modal)

  const handleCloseModal = () => dispatch({ type: 'CHANGE_MODAL', payload: { show: false } })

  return (
    <div className={`modal ${show && 'modal--visible'}`}>
      <div className={`modal__container ${show && "modal__container--visible"}`} style={{ maxWidth: modalWidth }}>
        {showCloseButton && <X className="modal__container__close" size={20} onClick={handleCloseModal} />}

        {title &&
          <div className="modal__container__header">
            <div className="modal__container__title" style={{ width: titleWidth }}>
              <h1>{title}</h1>
            </div>
          </div>
        }

        <div className="modal__container__content">
          {children}
        </div>

        {renderFooter &&
          <div className="modal__container__footer">
            {renderFooter}
          </div>
        }
      </div>
    </div>
  )
}

export default Modal
