export default function (state = {
  logoUrl: "",
  color: "",
  secondaryColor: "",
  fontColor: "",
  sac: "",
  support: "",
  groupId: "",
  modal: {
    show: false,
    showCloseButton: false,
    title: "",
    titleWidth: 256,
    modalWidth: 452,
    children: <></>,
    renderFooter: <></>
  },
  snackbar: ''
}, action) {
  switch (action.type) {
    case 'CHANGE_APP_LOGO_URL':
      return { ...state, logoUrl: action.payload }
    case 'CHANGE_APP_COLOR':
      return { ...state, color: action.payload }
    case 'CHANGE_APP_SECONDARY_COLOR':
      return { ...state, secondaryColor: action.payload }
    case 'CHANGE_APP_FONT_COLOR':
      return { ...state, fontColor: action.payload }
    case 'CHANGE_APP_SAC':
      return { ...state, sac: action.payload }
    case 'CHANGE_APP_SUPPORT':
      return { ...state, support: action.payload }
    case 'CHANGE_APP_GROUP_ID':
      return { ...state, groupId: action.payload }
    case 'CHANGE_MODAL':
      return { ...state, modal: { ...state.modal, ...action.payload } }
    case 'CHANGE_SNACKBAR':
      return { ...state, snackbar: action.payload }
    default:
      return state
  }
}
