import React, { useState } from 'react'
import _ from 'lodash'
import { ChevronDown } from 'react-feather'
import OutsideClickHandler from 'react-outside-click-handler'


import './index.scss'

function Select({ options = [{ text: "", nextQuestionId: "" }], marginBottom = "0px", onChange, value, contact = false }) {
  const [open, setOpen] = useState(false)

  function handleClickSelect(option) {
    setOpen(false)
    if (contact) {
      onChange(option.text)
    }
    else {
      onChange && onChange(option)
    }
  }
_.orderBy(options, 'text', 'asc')
  return (
    <div className="select" style={{ marginBottom: marginBottom }}>
      <div className="select__field" onClick={() => setOpen(true)}>
        {value ? value : <span>Selecione</span>}
        <ChevronDown />
      </div>
      <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
        <div className={open ? "select__options select__options--open" : "select__options"}>
          {_.orderBy(options, 'text', 'asc').map((option, index) => (
            <span key={index} onClick={() => handleClickSelect(option)}>{option.text}</span>
          ))}
        </div>
      </OutsideClickHandler>
    </div>
  )
}

export default Select
