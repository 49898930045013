import _ from 'lodash'

import './index.scss'

function CheckboxList({ options = [], value, onChange = () => { }, color }) {

  function handleCheck(text) {
    let list = []

    if (value) list = value.split(', ')

    if (list.includes(text)) {
      list = list.filter(item => item !== text)
    } else {
      list.push(text)
    }

    onChange && onChange(list.toString().replaceAll(',', ', '))
  }

  return (
    <>
      {options.length > 0 && options.map((option, index) => (
        <div key={index} className="checkbox-list-item" onClick={() => handleCheck(option.text)}>
          <div className="checkbox-list-item__checkmark__container" >
            <div className={value && value.split(', ').includes(option.text) ? "checkbox-list-item__checkmark--checked" : "checkbox-list-item__checkmark"} style={{ backgroundColor: color }}></div>
          </div>
          <label >
            {option.text}
          </label>
        </div>
      ))
      }
    </>
  )
}

export default CheckboxList
