import { useEffect, useState } from 'react'
import { validateCpf, cpf } from '../../constants/validate'
import { useDispatch } from 'react-redux'
import axios from 'axios'

import './index.scss'

function Input({ placeholder, value, onChange = () => { }, mask, path }) {
  const dispatch = useDispatch()
  const [currentValue, setCurrentValue] = useState('')

  useEffect(() => {
    if (value) {
      setCurrentValue(value)
    }
  }, [value])

  useEffect(() => {
    switch (mask) {
      case 'cpf':
        setCPF(currentValue)
        break;
      default:
        break;
    }
  }, [currentValue])

  function handleChange(e) {
    switch (mask) {
      case 'cpf':
        setCurrentValue(cpf(e.target.value))
        break;
      default:
        setCurrentValue(e.target.value)
        break;
    }
  }

  function setCPF(value) {
    if (value.length === 14) {
      if (validateCpf(value)) {
        onChange && onChange(value)

        // axios.post('')
        //   .then(response => {
        //     onChange && onChange(value)
        //   })
        //   .catch(error => {
        //     console.log(error)
        //   })
      } else {
        dispatch({ type: 'CHANGE_SNACKBAR', payload: "CPF incorreto" })
      }
    } else {
      onChange && onChange("")
    }
  }

  return (
    <div className="input">
      <input placeholder={placeholder} onChange={handleChange} value={currentValue} />
    </div>
  )
}

export default Input
