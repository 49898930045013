import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import './index.scss'

function Header() {
  const dispatch = useDispatch()
  const { logoUrl } = useSelector(state => state.app)

  return (
    <div className="header">
      <img src={logoUrl} />
      <Logo />
    </div>
  )
}

export default Header
