import React, { useEffect, useState } from 'react'
import { ArrowLeft } from 'react-feather'
import './index.scss'

function BackButton({ isFirst = true, isLast = false, onClick = () => { }, marginTop = "0px" }) {
  return (
    <div className="back-button" onClick={onClick} style={{ marginTop }}>
      <ArrowLeft /> {isFirst || isLast ? "Voltar para o site" : "Voltar"}
    </div>
  )
}

export default BackButton
