import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _, { set } from 'lodash'

import './index.scss'

function Snackbar() {
  const dispatch = useDispatch()
  const { snackbar } = useSelector(state => state.app)
  const [message, setMessage] = useState("")

  useEffect(() => {
    if (!_.isEmpty(snackbar)) {
      setMessage(snackbar)
      setTimeout(() => {
        dispatch({ type: 'CHANGE_SNACKBAR', payload: "" })
      }, 3000)
    }
  }, [snackbar])

  return (
    <div className={!_.isEmpty(snackbar) ? "snackbar snackbar--visible" : "snackbar"}>
      {message}
    </div>
  )
}

export default Snackbar
