import { ArrowDown, ArrowRight, DownloadCloud, ChevronDown, ChevronRight, ChevronLeft, Download, Plus } from 'react-feather';
import { Link } from 'react-router-dom'

import './index.scss'
import Loading from '../Loading'

const Button = ({
  scrollLink = false,
  to = "",
  type = 1,
  width = '100%',
  height = '60px',
  maxWidth = "auto",
  marginRight = "initial",
  fontSize = '14px',
  backgroundColor = 'black',
  color = 'white',
  arrowDown = false,
  arrowRight = false,
  downloadCloud = false,
  chevronDown = false,
  chevronRight = false,
  chevronLeft = false,
  download = false,
  plus = false,
  children,
  onClick,
  loading,
  submit = false,
  green = false,
  href = false,
  ...props
}) => {
  let style = {
    width,
    height,
    minHeight: height,
    maxWidth,
    marginRight,
    fontSize,
    backgroundColor,
    color
  }

  return (
    <div
      //to={to}
      style={style}
      className={`button`}
      onClick={onClick}
      {...props}>
      {
        loading ?
          <Loading color={green && 'green'} />
          :
          <span>
            {children}
            {arrowDown &&
              <ArrowDown size={30} />
            }
            {arrowRight &&
              <ArrowRight size={30} />
            }
            {downloadCloud &&
              <DownloadCloud size={20} />
            }
            {chevronDown &&
              <ChevronDown size={20} />
            }
            {chevronRight &&
              <ChevronRight size={20} />
            }
            {chevronLeft &&
              <ChevronLeft size={20} />
            }
            {download &&
              <Download size={20} />
            }
            {plus &&
              <Plus size={20} />
            }
          </span>
      }
      {submit && <button type='submit'></button>}
    </div>
  )
}

export default Button
