import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"

import Header from "./components/Header"
import Modal from "./components/Modal"
import Snackbar from "./components/Snackbar"

import Form from "./containers/Form"

import './styles/index.scss'

function App() {
  return (
    <Router>
      <div className='row'>
        <div className='column'>
          <Switch>
            <Route exact path={["/", "/:groupName"]}>
              <Form />
            </Route>
          </Switch>
        </div>
        <Modal />
      </div>
      <Snackbar />
    </Router>
  );
}

export default App;
