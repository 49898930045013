import { useEffect, useState } from 'react'
import './index.scss'

function ProgressBar({ step = 0, total = 10, color, marginTop = '0px' }) {
  const [progressLine, setProgressLine] = useState(0)

  useEffect(() => {
    setProgressLine((step / total) * 100)
  }, [step, total])


  return (
    <div className="progress-bar" style={{marginTop}}>
      <div className="progress-bar__line" style={{ backgroundColor: color, width: `${progressLine}%` }}>
      </div>
    </div>
  )
}

export default ProgressBar
